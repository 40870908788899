<template>
  <div v-if="open" class="popover black--text text-xs-center" ref="popover" id="speed-popover">
    <div style="font-size: 16px"> Speed </div>
    <!-- <v-radio-group v-model="playbackSpeed">
      <v-radio
        v-for="speed in playbackSpeeds"
        :key="speed"
        :label="speed"
        :value="speed"
      ></v-radio>
    </v-radio-group> -->
    <!-- <v-layout row wrap>
      <v-flex xs6 v-for="speed in playbackSpeeds" :key="speed" @click="setSpeed(speed)"> 
        <v-btn small fab :color="playbackSpeed == speed ? 'primary' : ''" @click="setSpeed(speed)"> {{ speed }}x </v-btn>
      </v-flex>
    </v-layout> -->
    <div class="popover-arrow"></div>
    <div v-for="o in playbackSpeeds" @click="setSpeed(o)" class="cpointer" style="font-size: 14px; height: 30px; line-height: 30px;">
        <span v-if="Number(o) == playbackSpeed" style="color: green;">✓ </span><span v-else>&nbsp; </span>{{ o }}x
      </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['speed', 'open'],
  data() {
    return {
      playbackSpeed: this.speed.toString(),
      playbackSpeeds: ['0.5', '1.0', '1.5', '2.0']
    };
  },
  methods: {
    setSpeed(speed) {
      this.sayDev("SetSpeed: " + speed)
      this.playbackSpeed = speed
      this.closePopover()
      this.$emit('update', speed)
    },
    openPopover() {
      this.open = true;
      document.addEventListener("click", this.handleClickOutside);
    },
    closePopover() {
      this.open = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      const popover = this.$refs.popover;
      if (popover && !popover.contains(event.target)) {
        this.closePopover();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  mounted() {
    this.playbackSpeed = this.speed.toString()
  },
  watch: {
    open(newVal) {
      this.playbackSpeed = this.speed.toString()
    },
  },
};
</script>

<style scoped>
.popover {
  /* position: absolute; */
  z-index: 100;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
}

</style>